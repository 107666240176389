html {
    height: 100%;
}

body {
    background-color: #F5F5F5;
    font-weight: 400;
    font-size: 13px;
    font-family: "Poppins", sans-serif;
    height: 100%;
}

.d-inline-flex {
    display: inline-flex !important;
}

.d-flex-to-block {
    display: flex !important;
}

@media (max-width: 763px) {
    .d-flex-to-block {
        display: block!important;
    }
}

.bg-dark-gray {
    background-color: #323539;
}

.bg-black {
    background-color: #131313;
}

.bg-light {
    background-color: #EBECED !important
}

.cursor-default {
    cursor: default;
}

.cursor-pointer {
    cursor: pointer;
}

.main-sidebar {
    background-color: #131313;
}

.main-sidebar .nav .nav-item .nav-link {
    border-bottom: 1px solid #323539;
    font-weight: 400;
    font-size: 12px;
    color: #ffffff;
    padding: .9375rem 1.5625rem;
}

.main-sidebar .nav--no-borders .nav-item .nav-link {
    border-bottom: 0;
}

.main-sidebar .nav .nav-item .nav-link.active, .main-sidebar .nav .nav-item .nav-link:hover, .main-sidebar .nav .nav-item.active, .main-sidebar .nav .nav-item:hover {
    box-shadow: none;
    background-color: #323539;
    color: #ffffff;
}

@media (max-width: 1020px) and (max-height: 840px) {
    .main-sidebar {
        /* max-width: 100px; */
        width: 100vw;
    }
}

.tabs {
    width: 100%;
}

.tab-item {
    float: left !important;
    width: 50% !important;
    font-size: 12px;
    border-top: 1px solid #EEF3F6;
    border-bottom: 1px solid #EEF3F6;
    text-align: center;
    padding: 14px 24px;
    color: #3d5170;
    text-transform: uppercase;
    cursor: pointer;
}

.tab-item.active {
    color: #ffffff;
    background-color: rgb(129, 142, 163);
}

.bullet {
    margin-right: .875rem;
    margin-top: 6px;
    margin-left: 1.4rem;
    background-color: #ffffff;
    height: 8px;
    width: 8px;
    border-radius: 50%;
}

.bullet.active {
    margin-right: .375rem;
    background-color: #EBB748;
    height: 14px;
    width: 14px;
    border-radius: 50%;
}

.nav-link a {
    color: #3d5170;
}

.main-footer {
    height: 2.2rem;
}

@media (max-width: 480px) {
    .hide-on-mobile {
        display: none;
    }
}

@media (max-width: 1024px) {
    .main-footer {
        height: auto !important;
    }
    .hide-on-tablet {
        display: none;
    }
    .job-title {
        margin: 1.2rem 0.6rem !important;
    }
}

.main-footer .copyright {
    color: #323539;
    font-family: "Poppins", sans-serif;
    font-size: 0.85rem;
}

.error__content h2 {
    font-size: 2.75rem;
}

.float-right {
    float: right;
    margin-right: 1rem;
}

.custom-nav-item {
    display: flex;
}

.custom-nav-link {
    min-width: 100%;
    font-family: "Poppins", sans-serif;
    color: #ffffff;
    border-bottom: 1px solid #323539;
    font-weight: 400;
    padding: .7rem 1.5625rem;
    font-size: .85rem;
}

a.custom-nav-link:hover {
    text-decoration: none;
    color: #ffffff;
    background-color: #323539;
}

.custom-nav-icon {
    min-width: 1.25rem;
    font-size: 90%;
    text-align: center;
    vertical-align: middle;
    will-change: color;
    color: #cacedb;
    transition: color .2s ease;
    margin-right: .375rem;
    font-size: 1.125rem;
    top: -1px;
}

a.custom-nav-link:hover>i {
    color: #EBB748;
}

.job-actions {
    position: fixed !important;
    background: white;
    z-index: 11;
    width: 100vw !important;
    margin-top: -4px;
}

.job-table {
    margin-top: 164px;
    overflow-y: auto;
    height: 100%;
}

.job-table-fixed {
    margin-top: 142px;
    overflow-y: auto;
    height: 100%;
}

.job-table-fixed th {
    position: sticky;
    top: 0;
}

.job-table-filler {
    height: 184px;
}

.job-table-headers {
    /* width: 100%; */
    width: calc(100vw - 140px);
    background-color: #EEF3F6;
    text-transform: uppercase;
    font-size: 11px;
    text-align: left;
    font-weight: 500;
}

.job-table-body {
    /* margin-top: 162px; */
    overflow-y: auto;
    background-color: #FFFFFF;
    width: 100%;
    height: 100%;
}

@media (max-width: 960px) {
    .job-table {
        margin-top: 0;
    }
    .job-actions {
        position: relative !important;
    }
}

@media (max-width: 550px) {
    .job-table {
        margin-top: 296px;
    }
    .job-actions {
        position: fixed !important;
    }
}

.card {
    border-radius: .1rem;
    box-shadow: none;
    border: 2px solid #e1e5eb;
}

.card-small {
    box-shadow: none;
    border: 2px solid #e1e5eb;
}

.badge {
    font-size: 90% !important;
}

.badge i {
    font-size: large;
}

.status-badge {
    font-size: 110% !important;
}

.no-border {
    border: none !important;
}

table {
    color: #3d5170 !important;
}

th, .row-header {
    font-weight: 500;
    text-transform: uppercase;
    font-size: 0.75rem;
    background-color: #EEF3F6;
}

table tr th {
    min-height: 32px;
    padding: 4px 8px;
}

tr:hover, .row-hightlight:hover {
    background-color: #EEF3F6;
}

.border-accent {
    border: 1rem solid #EBB748;
}

.btn-light {
    color: #5A6169 !important;
    border-color: #E4E4E4 !important;
    background-color: #EBECED !important;
}

.btn-white {
    border: 1rem solid #e1e5eb;
    background: #FFFFFF !important;
    color: #5A6169;
}

.btn.btn-white {
    border-color: #e1e5eb !important;
    background: #FFFFFF !important;
}

.btn-green {
    background-color: #81A94F !important;
    border-color: #81A94F !important;
}

.btn-yellow {
    background-color: #EBB748 !important;
    border-color: #EBB748 !important;
}

.btn-danger {
    background-color: #DD5757 !important;
    border-color: #DD5757 !important;
}

.btn-accent:hover {
    color: #fff;
    background-color: #818EA3;
    border-color: #818EA3;
    box-shadow: none;
}

.btn-primary {
    color: #fff;
    background-color: #3C5490;
    border-color: #3C5490;
}

.btn-primary:hover, .btn-primary:active, .btn-primary:focus {
    color: #fff;
    background-color: #5a6169 !important;
    border-color: #5a6169 !important;
    box-shadow: 0 5px 15px rgba(0, 0, 0, .05) !important;
}

.badge-warning {
    color: #FFFFFF;
    background-color: #EBB748;
}

.badge-high {
    color: #FFFFFF;
    background-color: #E19229;
}

.badge-danger {
    color: #FFFFFF;
    background-color: #DD5757;
}

.badge-secondary {
    color: #FFFFFF;
    background-color: #5a6169;
}

.badge-info {
    color: #FFFFFF;
    background-color: #40A1D8;
}

.badge-success {
    color: #FFFFFF;
    background-color: #81A94F;
}

.badge-light {
    background-color: #EAEBEC;
    color: #323539;
}

.center-block {
    transform: translate3d(0%, 12%, 0);
    padding-bottom: 1.875rem;
    margin: auto;
    max-width: 600px;
}

.popup-block {
    transform: translate3d(0%, 4%, 0);
    padding-bottom: 1.875rem;
    margin: auto;
    max-width: 600px;
}

.full-page {
    background-image: url(./images/banner.jpg);
    /* background-image: url(./images/banner-qa.png); */
    background-repeat: no-repeat;
    background-size: cover;
    left: 0;
    position: fixed;
    right: 0;
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
}

.popup {
    background: rgba(0, 0, 0, .45);
    left: 0;
    position: fixed;
    right: 0;
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    z-index: 100
}

.job-list {
    /* height: 100%; */
    height: calc(100vh - (3.75rem + 2.2rem + 66px));
    background: #ffffff;
    border-right: 2px solid #e1e5eb;
}

.job-list__item {
    padding: 0;
    border-bottom: 1px solid #e1e5eb;
    cursor: pointer;
}

.job-list__item:hover {
    background-color: #E8EDF1;
}

.job-list__avatar {
    display: table;
    font-size: 16px;
    height: 42px;
    margin: 4% auto 0;
    text-align: center;
    transition: .4s;
    width: 42px;
    border-radius: 21px;
    border: 2px solid #e1e5eb;
}

.job-list__avatar-content {
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    display: table-cell;
    font-weight: 700;
    height: 100%;
    position: relative;
    vertical-align: middle;
    width: 100%;
}

.dropdown-item:focus, .dropdown-item:hover {
    color: #333333 !important;
    background-color: #E8EDF1 !important;
    border: none;
    outline: none !important
}

.custom-control-label::before {
    margin-top: 2px;
    width: 1.4rem;
    height: 1.4rem;
    border: 2px solid #e1e5eb;
}

.custom-control-input:checked~.custom-control-label::before {
    color: #fff;
    border-color: transparent;
    background-color: #81A94F;
    box-shadow: none;
}

.custom-checkbox .custom-control-label::after {
    width: 8px;
    height: 16px;
}

.input-group-lg>.custom-select, .input-group-lg>.form-control:not(textarea) {
    font-size: 14px;
}

.input-group-lg>.custom-select::placeholder, .input-group-lg>.form-control:not(textarea)::placeholder {
    font-size: 14px;
}

.react-datepicker__time-container {
    box-shadow: 0 0.5rem 4rem rgba(0, 0, 0, 0.11), 0 10px 20px rgba(0, 0, 0, 0.05), 0 2px 3px rgba(0, 0, 0, 0.06);
    width: 110px;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box {
    width: 100px;
}